import React from 'react'

import "./footer.css";

const Footer = () => {

  return (
    <div className='footer'>
      <div className='footer-banner-container'>
        <div className='banner-text'>LIGHT UP YOUR SPARK&nbsp;&nbsp;&nbsp;&nbsp;LIGHT UP YOUR SPARK&nbsp;&nbsp;&nbsp;&nbsp;LIGHT UP YOUR SPARK&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <div className='banner-text'>LIGHT UP YOUR SPARK&nbsp;&nbsp;&nbsp;&nbsp;LIGHT UP YOUR SPARK&nbsp;&nbsp;&nbsp;&nbsp;LIGHT UP YOUR SPARK&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      <div className='footer-social-container'>
        <div className='social'>
          <div className='social-text'><a href='https://www.facebook.com/profile.php?id=100089884605854' rel='noopener noreferrer' target='_blank' >facebook</a></div>
          <div className='social-text'><a href='https://www.instagram.com/firelight_anim/' rel="noopener noreferrer" target='_blank'>instagram</a></div>
          <div className='social-text'><a href='https://twitter.com/firelight_anim' rel="noopener noreferrer" target='_blank'>twitter</a></div>
          <div className='social-text'><a href='https://www.linkedin.com/company/firelight-animation-studio/' rel="noopener noreferrer" target='_blank'>linkedIn</a></div>
        </div>
        <div className='email'>
          <div className='email-text'>Ready to bring your ideas to life?</div>
          <div className='email-text'>Contact us now to spark your next project!</div>
          <div className='email-text'>info@firelightanimation.com</div>
        </div>
      </div>
    </div>
  )
}

export default Footer
