import React, { useState, useEffect } from 'react'
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";

import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';

import "./challenge.css";
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import "swiper/css";
import 'swiper/css/pagination';

import ImgLeftBlobs from "../../images/project/left.webp";
import ImgRightBlobs from "../../images/project/right.webp";
import ImgBlobs03 from "../../images/challenge/3.webp";
import ImgBlobs04 from "../../images/challenge/4.webp";
import ImgBackground02 from "../../images/challenge/background-contest-01.webp";
import ImgBackground03 from "../../images/challenge/background-contest-02.webp";
import ImgGetTheRig from "../../images/challenge/umbras.webp";
import LupinHouseBlack from "../../images/challenge/lupin_house_black.webp";
import LupinHouseBlackV2 from "../../images/challenge/lupin_house_black_v2.webp";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown"
import { FaLinkedinIn } from 'react-icons/fa';
import ImgJury01 from "../../images/challenge/guillermo_careaga.webp";
import ImgJury02 from "../../images/challenge/steven_morris.webp";
import ImgJury03 from "../../images/challenge/alvise_avati.webp";
import ImgJury04 from "../../images/challenge/margin_marques.webp";
import ImgJury05 from "../../images/challenge/yensen_quintero.webp";
import ImgAlert from "../../images/challenge/alert.webp";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return isMobile ? children : null
}


export default function Project() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [creditsShow, setCreditsShow] = useState(false);

  useEffect(() => {

    window.scrollTo(0, 0); 

  }, []); 

  const toggleIcon = () => {
    setCreditsShow(!creditsShow);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.firstName || !formData.lastName || !formData.email) {
      alert('Please fill in all the fields.');
      return;
    }

    setIsLoading(true);

    // Get current date and format it

    const currentDate = new Date();
    const options = { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      hour12: false 
    };

    const formattedDate = currentDate.toLocaleString('es-ES', options).replace(',', '');

    // Add formatted date to formData

    const formDataWithDate = {
      ...formData,
      dateTime: formattedDate,
    };

    // Send data to backend

    try {
      const response = await fetch('https://api.firelightanimation.com/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataWithDate),
      });

      if (response.ok) {
        setIsSubmitted(true);
        window.location.href = 'https://api.firelightanimation.com/download';
      } else {
        alert('Failed to submit data');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal" onClick={(e) => {
            if (e.target.classList.contains("modal")) {
              setIsModalOpen(false);
              setIsSubmitted(false);
              setShowTerms(false);
              setFormData({
                firstName: "",
                lastName: "",
                email: ""
              });
            }
          }}
        >
          <div className="modal-content">
            <title className='form-title'>{isSubmitted ? "THANK YOU!" : "DOWNLOAD FREE RIG"}</title>
            {!isSubmitted ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label">First Name</label>
                  <input
                    placeholder='required'
                    type='text'
                    name='firstName'
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <input
                    placeholder='required'
                    type='text'
                    name='lastName'
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Email Address</label>
                  <input
                    placeholder='required'
                    type='email'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="terms-text">
                    By downloading the rig you agree our <strong
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => setShowTerms(!showTerms)}
                  >
                    Terms and Personal Data Processing
                  </strong>. Firelight Animation may use your email address for purposes outlined in our Privacy Policy.
                  </div>
                </div>
                <div className={`terms-container ${showTerms ? 'visible' : ''}`}>
                  <div className={`terms-content`}>
                    <p style={{ textAlign: 'center' }}><strong>Terms and Conditions</strong></p>
                    <p style={{ textAlign: 'justify' }}>Welcome to The Interview Challenge. By registering on our website to download educational material, you agree to comply with the following conditions:</p>
                    <ul>
                      <li style={{ textAlign: 'justify' }}>Content Ownership: The educational material available for download on this website is a free material. Users may use this content only for educational or personal purposes and must not redistribute, modify, or resell the material.</li>
                      <li style={{ textAlign: 'justify' }}>Website Use: Users agree to use this website lawfully and not interfere with its operation or access restricted areas without authorization.</li>
                      <li style={{ textAlign: 'justify' }}>Registration and Security: When registering on the website, users guarantee that the information provided is accurate and complete. Users are responsible for maintaining the confidentiality of their account and password and agree to notify Firelight Animation Studio immediately of any unauthorized use of their account.</li>
                      <li style={{ textAlign: 'justify' }}>Limitation of Liability: Firelight Animation Studio is not liable for any direct or indirect damages arising from the use of the educational material or the website, including damages related to data loss or service interruptions.</li>
                      <li style={{ textAlign: 'justify' }}>Modification of Terms: Firelight Animation Studio reserves the right to modify these terms and conditions at any time. Users will be notified of any changes via the website or email.</li>
                    </ul>
                    <p style={{ textAlign: 'center' }}><strong>Personal Data Processing</strong></p>
                    <p style={{ textAlign: 'justify' }}>In compliance with international data protection legislation, Firelight Animation Studio guarantees the privacy and protection of the personal data of users registered on its platform.</p>
                    <ul>
                      <li style={{ textAlign: 'justify' }}>Data Collection: By registering on our website, we collect personal information such as your name, email address, and any other relevant information required to provide you with the requested educational material.</li>
                      <li style={{ textAlign: 'justify' }}>Purpose of Data Processing: Personal data will be processed exclusively for:</li>
                        <ul>
                          <li style={{ textAlign: 'justify' }}>Facilitating the download of educational materials.</li>
                          <li style={{ textAlign: 'justify' }}>Communicating with you about updates, promotions, and news related to educational material (if you have consented to this).</li>
                          <li style={{ textAlign: 'justify' }}>Complying with legal and regulatory obligations.</li>
                        </ul>
                      <li style={{ textAlign: 'justify' }}>User Rights: Users have the right to access, rectify, delete, object to, and restrict the processing of their personal data at any time. To exercise these rights, users can contact us via email at info@firelightanimation.com.</li>
                      <li style={{ textAlign: 'justify' }}>Data Retention: Your personal data will be stored only for the necessary time to fulfill the purposes mentioned above or as required by law.</li>
                      <li style={{ textAlign: 'justify' }}>Data Security: We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, loss, destruction, or alteration.</li>
                    </ul>
                  </div>
                </div>
                <button type='submit' disabled={isLoading}>
                  {isLoading ? 'SUBMITTING...' : 'SUBMIT AND DOWNLOAD'}
                </button>
              </form>
            ) : (
              <p className="thanks-text">Your download will begin shortly.</p>
            )}
          </div>
        </div>
      )}
      <Desktop>
        <div className='projectContainer'>
          <img className='left-blobs' src={ImgLeftBlobs} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
          <img className='right-blobs' src={ImgRightBlobs} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
          <title className='titleProject'>JOIN THE INTERVIEW CHALLENGE</title>
          <title className='subTitleProject'>A FAN ART AND EDUCATIONAL PROJECT</title>
          <title className='subTitleProject'>ANIMATE WITH OUR FREE RIG & WIN A <a href="https://www.lupinhouse.com/" target="_blank" rel="noopener noreferrer"><img src={LupinHouseBlackV2} alt="Lupin House" className='lupinImage' /></a>COURSE</title>
          <div className='desc-project-container'>
            <p className='descriptionProject'>Firelight Animation Studio and <a href="https://www.lupinhouse.com/" target="_blank" rel="noopener noreferrer"><strong>LUPIN HOUSE</strong></a> have teamed up to launch The Interview Challenge, AN EDUCATIONAL PROJECT for the 3D Community (READ DISCLAIMER BELOW). Download Firelight's free rig, create your animation, and submit it! A jury of top animation experts will choose the winner, who will receive an amazing prize: <strong>an animation course at Lupin House</strong> in London. The course includes 6 weeks of mentorship with one of their mentors and access to 8 feature-quality character rigs. Enjoy the teaser we've prepared with the rig! If you want to know the contest rules and meet the jury members, scroll to the bottom of the page!</p>
            <div className='button-container'>
              <div className='btn' onClick={handleOpenModal}>
                DOWNLOAD FREE RIG HERE
                <div className='click'>
                <svg stroke="currentColor" fill="none" strokeWidth="1.2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m9 9 5 12 1.774-5.226L21 14 9 9z"></path><path d="m16.071 16.071 4.243 4.243"></path><path d="m7.188 2.239.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656-2.12 2.122"></path></svg></div>
              </div>
              <a href="https://www.lupinhouse.com/firelight" target="_blank" rel="noopener noreferrer">
                <div className='btn'>
                  JOIN THE CONTEST HERE
                <div className='click'><svg stroke="currentColor" fill="none" strokeWidth="1.2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m9 9 5 12 1.774-5.226L21 14 9 9z"></path><path d="m16.071 16.071 4.243 4.243"></path><path d="m7.188 2.239.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656-2.12 2.122"></path></svg></div>
              </div></a>
            </div>
          </div>
          <div className='video-container-challenge'>
            <iframe
              src="https://www.youtube.com/embed/HWc3go35Ee8?autoplay=1&mute=1&loop=1&playlist=HWc3go35Ee8"
              title="The Interview - Hulk"
              style={{ border: "none" }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen">
            </iframe>
          </div>
          <div className='contestContainer'>
            <img className='challenge_bkg_02' src={ImgBackground02} alt="background" />
            <div className="getTheRigContainer" onClick={handleOpenModal}>
              <img className='getTheRig' src={ImgGetTheRig} alt='getTheRig'/>
              <div className='clickGetTheRig'><svg stroke="currentColor" fill="none" strokeWidth="1.2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m9 9 5 12 1.774-5.226L21 14 9 9z"></path><path d="m16.071 16.071 4.243 4.243"></path><path d="m7.188 2.239.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656-2.12 2.122"></path></svg></div>
            </div>
            <img className='challenge_bkg_03' src={ImgBackground03} alt="background" />
            <title className='contestTitle'>JOIN THE CONTEST</title>
            <title className='contestSubtitle'>THE CONTEST RULES</title>
            <p className='contestDetails'>- All users who download the rig must register with their name and email address.</p>
            <p className='contestDetails'>- To join the contest, participants must register via the Lupin House website through the 'Join the Contest' link</p>
            <p className='contestDetails'>- Animators will have a total of 2 months to submit their animation: from October 22nd to December 22nd.</p>
            <p className='contestDetails'>- Participants must upload their animation to the platform provided by Lupin & Firelight.</p>
            <p className='contestDetails'>- It is strictly forbidden to create animations that promote hate, racism, sexism, or depict sexual or highly inappropriate behavior. Any participant who violates this rule will be disqualified. Firelight & Lupin House are not responsible for the content produced by participants.</p>
            <p className='contestDetails'>- A countdown will be available on this website and on the Lupin House website to show the remaining days of the challenge.</p>
            <p className='contestDetails'>- Participants who share their animations on social media and repost Firelight and Lupin House's official posts will be given extra consideration for the prize.</p>
            <img className='lupin_logo_black' src={LupinHouseBlack} alt="logo" />
            <div className='button-container-02'>
              <a href="https://www.lupinhouse.com/firelight" target="_blank" rel="noopener noreferrer"><button>JOIN THE CONTEST HERE</button></a>
            </div>
            <div className='clockContainer'>
              <FlipClockCountdown 
                to={new Date('2024-12-23T00:00:00')}
                labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                labelStyle={{ fontSize: '3vw', textTransform: 'uppercase' }}
                digitBlockStyle={{ width: '6.5vw', height: '8vw', fontSize: '7vw' }}
                duration={0.5} 
              />
            </div>
          </div>
          <div className='video-container-challenge'>
            <iframe
              src="https://www.youtube.com/embed/SyEwTFfY1ho?autoplay=1&mute=1&loop=1&playlist=SyEwTFfY1ho"
              title="The Interview - RIG"
              style={{ border: "none" }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen">
            </iframe>
          </div>
          <div className='jury-container'>
            <title className='juryTitle'>THE JURY</title>
            <div className="cardsContainer">
              <div className="juryCard">
                <img src={ImgJury01} alt="Guillermo Careaga"/>
                <div id="overlay-01" className="overlay-challenger">
                  <div className='jury-name'>Guillermo Careaga</div>
                  <div className='jury-charge'>Animation Supervisor at Dreamworks</div>
                  <div className='jury-logo-01'/>
                  <a href="https://www.linkedin.com/in/guillecareaga/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                </div>
              </div>
              <div className="juryCard">
                <img src={ImgJury02} alt="Steven Morris" />
                <div id="overlay-02" className="overlay-challenger">
                  <div className='jury-name'>Steven Morris</div>
                  <div className='jury-charge'>Lupin House CEO & Senior Animator</div>
                  <div className='jury-logo-02'/>
                  <a href="https://www.linkedin.com/in/steven-j-504a3a1a5/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                </div>
              </div>
              <div className="juryCard">
                <img src={ImgJury03} alt="Alvise Alvati" />
                <div id="overlay-03" className="overlay-challenger">
                  <div className='jury-name'>Alvise Alvati</div>
                  <div className='jury-charge'>Animation Supervisor at Framestore</div>
                  <div className='jury-logo-03'/>
                  <a href="https://www.linkedin.com/in/alvize/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                </div>
              </div>
              <div className="juryCard">
                <img src={ImgJury04} alt="Magín Marques" />
                <div id="overlay-04" className="overlay-challenger">
                  <div className='jury-name'>Magín Marques</div>
                  <div className='jury-charge'>Little Zoo Animator</div>
                  <div className='jury-logo-04'/>
                  <a href="https://www.linkedin.com/in/maginmarques/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                </div>
              </div>
              <div className="juryCard">
                <img src={ImgJury05} alt="Yensen Quintero" />
                <div id="overlay-05" className="overlay-challenger">
                  <div className='jury-name'>Yensen Quintero</div>
                  <div className='jury-charge'>Firelight Animation Director</div>
                  <div className='jury-logo-05'/>
                  <a href="https://www.linkedin.com/in/yensenquintero/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className='disclaimerContainer'>
            <img className='disclaimerAlert' src={ImgAlert} alt="disclaimer" />
            <title className='disclaimerTitle'>DISCLAIMER MESSAGE</title>
            <p className='disclaimerText'>This fan art was created exclusively for educational purposes, with no intent for profit, and in collaboration with multiple industry artists. It is intended for use within CG animation schools to develop and teach technical skills. This character is a humorous reinterpretation inspired by Hulk, originally created by Stan Lee and Jack Kirby, with the copyright owned by Marvel. This fan art is neither affiliated with nor endorsed by Marvel or Disney and is not intended for commercial use.</p>
          </div>
          <div className='creditsContainer'onClick={toggleIcon}>
            <div className='creditsTitleContainer'>
              <div className='credits'>Credits</div>
              <div className='creditsIcon'>
                {creditsShow ? <PiCaretUpBold /> : <PiCaretDownBold />} 
              </div>
            </div>
            <div className={`creditsDetails ${creditsShow ? 'active' : ''}`}>
              <div className='creditsText'>Directors: Marc Martín y Yensen Quintero</div>
              <div className='creditsText'>Executive Producer: Berni López Campà</div>
              <div className='creditsText'>Original Idea: Irene Ayguade, Yensen Quintero y Marc Martín</div>
              <div className='creditsText'>Voice Actor: Sean Boyce Johnson</div>
              <div className='creditsText'>Editing & Sound Design Supervisor: Yensen Quintero</div>
              <div className='creditsText'>Art Director: Marc Martín</div>
              <div className='creditsText'>Animation Director: Magin Marques</div>
              <div className='creditsText'>CG Supervisor: Víctor Zudaire</div>
              <div className='creditsText'>Technical Director: Gaspar Segura</div>
              <div className='creditsText'>Layout Supervisor: Yensen Quintero y Magin Marques</div>
              <div className='creditsText'>Lighting & Comp Supervisors: Víctor Zudaire y Gaspar Segura</div>
              <div className='creditsText'>Character Designer: Marc Martín y Marco Furtado</div>
              <div className='creditsText'>Cloth Artist: Iosu Vermeesch Amor</div>
              <div className='creditsText'>Environment Generalist: Víctor Zudaire</div>
              <div className='creditsText'>Character Modeling Artist: Marc Martín</div>
              <div className='creditsText'>Rigging Artist: Ivan Cuenca Ruiz</div>
              <div className='creditsText'>Additional Rigging Feedback: Jesus Coscolla y Víctor Zudaire</div>
              <div className='creditsText'>Character Lookdev Artist: Marc Martín</div>
              <div className='creditsText'>Producers: Berni López Campà y Angelica Carbonell</div>
              <div className='creditsText'>Groom Artist: Marc Martín</div>
              <div className='creditsText'>Character Fx Artist: Gerard Grifell Botarques</div>
              <div className='creditsText'>Lighting & Comp Artist: Ivan Martínez Casas</div>
              <div className='creditsText'>Library Animator: Charles Icay</div>
              <div className='creditsText'>Web Developer: Gonzalo Granile</div>
              <div className='creditsText'>Special Thanks To: Luis Gaspardo, María Valle, Fran Rodher, Raúl Negrin y Guillem Ramisa.</div>
            </div>
            <img className='challenge_blobs_03' src={ImgBlobs03} alt="blob" />
            <img className='challenge_blobs_04' src={ImgBlobs04} alt="blob" />
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className='projectContainer'>
          <img className='left-blobs' src={ImgLeftBlobs} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
          <img className='right-blobs' src={ImgRightBlobs} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
          <title className='titleProject'>JOIN THE INTERVIEW CHALLENGE</title>
          <title className='subTitleProject'>A FAN ART AND EDUCATIONAL PROJECT</title>
          <title className='subTitleProject'>ANIMATE WITH OUR FREE RIG & </title>
          <title className='subTitleProject-02'>WIN A <a href="https://www.lupinhouse.com/" target="_blank" rel="noopener noreferrer"><img src={LupinHouseBlackV2} alt="Lupin House" className='lupinImage' /></a> COURSE</title>
          <div className='desc-project-container'>
          <p className='descriptionProject'>Firelight Animation Studio and <a href="https://www.lupinhouse.com/" target="_blank" rel="noopener noreferrer"><strong>LUPIN HOUSE</strong></a> have teamed up to launch The Interview Challenge for the 3D community (READ DISCLAIMER BELOW). Download Firelight's free rig, create your animation, and submit it! A jury of top animation experts will choose the winner, who will receive an amazing prize: <strong>an animation course at Lupin House</strong> in London. The course includes 6 weeks of mentorship with one of their mentors and access to 8 feature-quality character rigs. Enjoy the teaser we've prepared with the rig! If you want to know the contest rules and meet the jury members, scroll to the bottom of the page!</p>
            <div className='button-container'>
              <div className='btn' onClick={handleOpenModal}>
                DOWNLOAD FREE RIG HERE
                <div className='click'>
                <svg stroke="currentColor" fill="none" strokeWidth="1.2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m9 9 5 12 1.774-5.226L21 14 9 9z"></path><path d="m16.071 16.071 4.243 4.243"></path><path d="m7.188 2.239.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656-2.12 2.122"></path></svg></div>
              </div>
              <a href="https://www.lupinhouse.com/firelight" target="_blank" rel="noopener noreferrer">
                <div className='btn'>
                  JOIN THE CONTEST HERE
                <div className='click'><svg stroke="currentColor" fill="none" strokeWidth="1.2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m9 9 5 12 1.774-5.226L21 14 9 9z"></path><path d="m16.071 16.071 4.243 4.243"></path><path d="m7.188 2.239.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656-2.12 2.122"></path></svg></div></div>
              </a>
            </div>
          </div>
          <div className='video-container-challenge'>
            <iframe
              src="https://www.youtube.com/embed/HWc3go35Ee8?autoplay=1&mute=1&loop=1&playlist=HWc3go35Ee8"
              title="The Interview - Hulk"
              style={{ border: "none" }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen">
            </iframe>
          </div>
          <div className='contestContainer'>
            <img className='challenge_bkg_02' src={ImgBackground02} alt="background" />
            <div className="getTheRigContainer" onClick={handleOpenModal}>
              <img className='getTheRig' src={ImgGetTheRig} alt='getTheRig'/>
              <div className='clickGetTheRig'><svg stroke="currentColor" fill="none" strokeWidth="1.2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m9 9 5 12 1.774-5.226L21 14 9 9z"></path><path d="m16.071 16.071 4.243 4.243"></path><path d="m7.188 2.239.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656-2.12 2.122"></path></svg></div>
            </div>
            <img className='challenge_bkg_03' src={ImgBackground03} alt="background" />
            <title className='contestTitle'>JOIN THE CONTEST</title>
            <title className='contestSubtitle'>THE CONTEST RULES</title>
            <p className='contestDetails'>- All users who download the rig must register with their name and email address.</p>
            <p className='contestDetails'>- To join the contest, participants must register via the Lupin House website through the 'Join the Contest' link</p>
            <p className='contestDetails'>- Animators will have a total of 2 months to submit their animation: from October 22nd to December 22nd.</p>
            <p className='contestDetails'>- Participants must upload their animation to the platform provided by Lupin & Firelight.</p>
            <p className='contestDetails'>- It is strictly forbidden to create animations that promote hate, racism, sexism, or depict sexual or highly inappropriate behavior. Any participant who violates this rule will be disqualified. Firelight & Lupin House are not responsible for the content produced by participants.</p>
            <p className='contestDetails'>- A countdown will be available on this website and on the Lupin House website to show the remaining days of the challenge.</p>
            <p className='contestDetails'>- Participants who share their animations on social media and repost Firelight and Lupin House's official posts will be given extra consideration for the prize.</p>
            <img className='lupin_logo_black' src={LupinHouseBlack} alt="logo" />
            <div className='button-container-02'>
              <a href="https://www.lupinhouse.com/firelight" target="_blank" rel="noopener noreferrer"><button>JOIN THE CONTEST HERE</button></a>
            </div>
            <div className='clockContainer'>
              <FlipClockCountdown 
                to={new Date('2024-12-23T00:00:00')}
                labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                labelStyle={{ fontSize: 25, textTransform: 'uppercase' }}
                digitBlockStyle={{ width: 35, height: 80, fontSize: 50 }}
                duration={0.5} 
              />
            </div>
          </div>
          <div className='video-container-challenge'>
            <iframe
              src="https://www.youtube.com/embed/SyEwTFfY1ho?autoplay=1&mute=1&loop=1&playlist=SyEwTFfY1ho"
              title="The Interview - RIG"
              style={{ border: "none" }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen">
            </iframe>
          </div>
          <div className='jury-container'>
            <title className='juryTitle'>THE JURY</title>
            <Swiper pagination={true} modules={[Pagination]} loop={true} className="challenger-swiper-container">
              <SwiperSlide>
                <div className="juryCard">
                  <img src={ImgJury01} alt="Guillermo Careaga"/>
                  <div id="overlay-01" className="overlay-challenger">
                    <div className='jury-name'>Guillermo Careaga</div>
                    <div className='jury-charge'>Animation Supervisor at Dreamworks</div>
                    <div className='jury-logo-01'/>
                    <a href="https://www.linkedin.com/in/guillecareaga/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="juryCard">
                  <img src={ImgJury02} alt="Steven Morris" />
                  <div id="overlay-02" className="overlay-challenger">
                    <div className='jury-name'>Steven Morris</div>
                    <div className='jury-charge'>Lupin House CEO & Senior Animator</div>
                    <div className='jury-logo-02'/>
                    <a href="https://www.linkedin.com/in/steven-j-504a3a1a5/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="juryCard">
                  <img src={ImgJury03} alt="Alvise Alvati" />
                  <div id="overlay-03" className="overlay-challenger">
                    <div className='jury-name'>Alvise Alvati</div>
                    <div className='jury-charge'>Animation Supervisor at Framestore</div>
                    <div className='jury-logo-03'/>
                    <a href="https://www.linkedin.com/in/alvize/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="juryCard">
                  <img src={ImgJury04} alt="Magín Marques" />
                  <div id="overlay-04" className="overlay-challenger">
                    <div className='jury-name'>Magín Marques</div>
                    <div className='jury-charge'>Little Zoo Animator</div>
                    <div className='jury-logo-04'/>
                    <a href="https://www.linkedin.com/in/maginmarques/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="juryCard">
                  <img src={ImgJury05} alt="Yensen Quintero" />
                  <div id="overlay-05" className="overlay-challenger">
                    <div className='jury-name'>Yensen Quintero</div>
                    <div className='jury-charge'>Firelight Animation Director</div>
                    <div className='jury-logo-05'/>
                    <a href="https://www.linkedin.com/in/yensenquintero/" rel="noopener noreferrer" target='_blank'><FaLinkedinIn className="linkedin-jury"/></a>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className='disclaimerContainer'>
            <img className='disclaimerAlert' src={ImgAlert} alt="disclaimer" />
            <title className='disclaimerTitle'>DISCLAIMER MESSAGE</title>
            <p className='disclaimerText'>This fan art was created exclusively for educational purposes, with no intent for profit, and in collaboration with multiple industry artists. It is intended for use within CG animation schools to develop and teach technical skills. This character is a humorous reinterpretation inspired by Hulk, originally created by Stan Lee and Jack Kirby, with the copyright owned by Marvel. This fan art is neither affiliated with nor endorsed by Marvel or Disney and is not intended for commercial use.</p>
            <img className='challenge_blobs_03' src={ImgBlobs03} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
            <img className='challenge_blobs_04' src={ImgBlobs04} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
          </div>
          <div className='creditsContainer'onClick={toggleIcon}>
            <div className='creditsTitleContainer'>
              <div className='credits'>Credits</div>
              <div className='creditsIcon'>
                {creditsShow ? <PiCaretUpBold /> : <PiCaretDownBold />} 
              </div>
            </div>
            <div className={`creditsDetails ${creditsShow ? 'active' : ''}`}>
              <div className='creditsText'>Directors: Marc Martín y Yensen Quintero</div>
              <div className='creditsText'>Executive Producer: Berni López Campà</div>
              <div className='creditsText'>Original Idea: Irene Ayguade, Yensen Quintero y Marc Martín</div>
              <div className='creditsText'>Voice Actor: Sean Boyce Johnson</div>
              <div className='creditsText'>Editing & Sound Design Supervisor: Yensen Quintero</div>
              <div className='creditsText'>Art Director: Marc Martín</div>
              <div className='creditsText'>Animation Director: Magin Marques</div>
              <div className='creditsText'>CG Supervisor: Víctor Zudaire</div>
              <div className='creditsText'>Technical Director: Gaspar Segura</div>
              <div className='creditsText'>Layout Supervisor: Yensen Quintero y Magin Marques</div>
              <div className='creditsText'>Lighting & Comp Supervisors: Víctor Zudaire y Gaspar Segura</div>
              <div className='creditsText'>Character Designer: Marc Martín y Marco Furtado</div>
              <div className='creditsText'>Cloth Artist: Iosu Vermeesch Amor</div>
              <div className='creditsText'>Environment Generalist: Víctor Zudaire</div>
              <div className='creditsText'>Character Modeling Artist: Marc Martín</div>
              <div className='creditsText'>Rigging Artist: Ivan Cuenca Ruiz</div>
              <div className='creditsText'>Additional Rigging Feedback: Jesus Coscolla y Víctor Zudaire</div>
              <div className='creditsText'>Character Lookdev Artist: Marc Martín</div>
              <div className='creditsText'>Producers: Berni López Campà y Angelica Carbonell</div>
              <div className='creditsText'>Groom Artist: Marc Martín</div>
              <div className='creditsText'>Character Fx Artist: Gerard Grifell Botarques</div>
              <div className='creditsText'>Lighting & Comp Artist: Ivan Martínez Casas</div>
              <div className='creditsText'>Library Animator: Charles Icay</div>
              <div className='creditsText'>Web Developer: Gonzalo Granile</div>
              <div className='creditsText'>Special Thanks To: Luis Gaspardo, María Valle, Fran Rodher, Raúl Negrin y Guillem Ramisa.</div>
            </div>
            <img className='challenge_blobs_03' src={ImgBlobs03} alt="blob" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true"/>
            <img className='challenge_blobs_04' src={ImgBlobs04} alt="blob" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true"/>
          </div>
        </div>
      </Mobile>
    </>
  )
}