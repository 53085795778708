import React, { useState, useEffect } from 'react'
import "./careers.css";
import ImgUmbra from "../../images/footer/umbra.webp";

export default function Careers() {
  const [positionOpen, setPositionOpen] = useState(false);
  const [vacancies, setVacancies] = useState([]);

  useEffect(() => {
    fetch('/careers.json') // Ruta relativa al archivo en la carpeta "public"
      .then(response => response.json())
      .then(data => setVacancies(data))
      .catch(error => console.error('Error fetching vacancies:', error));
  }, []);

  const handleVacancies = (index) => {
    setPositionOpen(index === positionOpen ? null : index);
  };

  return (
      <div className='careersContainer'>
        <img className='umbra' src={ImgUmbra} alt="umbra" />
        <div className='blobsContainer'>
          <div className='title'>CAREERS</div>
          <div className='textCareers-01'>We have big ambitions and we want you to help us achieve them</div>
          <div className='textCareers-02'>Together we can do great things</div>
          <div className='textCareers-01'>If you're interested in careers and open positions, please contact us</div>
          <div className='textCareers-01' style={{ color: '#413C7D'}}>info@firelightanimation.com</div>
          <h1 className='subTitle'>Current vacancies</h1>
          <div className='vacanciesContainer'>
          {vacancies.map((vacancy, index) => (
            <div key={index}>
              <div className={`textVacancies ${positionOpen === index ? 'selected' : ''}`} onClick={() => handleVacancies(index)}>{vacancy.title}</div>
              {positionOpen === index && (
                <div>
                  {vacancy.details.map((detail, detailIndex) => (
                    <div key={detailIndex} className='detailsVacancies'>{detail}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
          </div>
        </div>
      </div>
  );
}