import React, { useState } from 'react';
import "./contactus.css";
import ImgUmbra from "../../images/footer/umbra.webp";

import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import emailjs from '@emailjs/browser';

import ImgUmbraHeart from "../../images/contactus/umbra-heart.webp";

// Ajuste de pantalla

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  return isMobile ? children : null
}

export default function OurWork() {
  
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = (data) => {

    const { email, subject, message } = data;

    const templateParams = {
      from_name: email,
      to_name: 'info@firelightanimation.com', // Cambia esto al correo electrónico del destinatario
      subject,
      message,
    };

    emailjs.send('service_7sybj4u', 'template_gx68cuj', templateParams, 'N_EjvYHThUi0-eyQq')
      .then(() => {
        console.log('Correo electrónico enviado exitosamente');
      })
      .catch((error) => {
        console.error('Error al enviar el correo electrónico:', error);
      });
  };

  const handleFormSubmit = (data) => {
    if (data.email && data.subject && data.message) {
      onSubmit(data);
      setIsFormSubmitted(true);
      reset(); // Restablecer el formulario después de enviarlo exitosamente
    }
  };

  return (
    <>
    <Desktop>
      <div className='contactUsContainer'>
      <img className='umbra' src={ImgUmbra} alt="umbra" />
        <div className='blobs-01-Container'>
          <img className='heart_umbra' src={ImgUmbraHeart} alt="umbra" />
          <div className='titleContactUs'>BRING YOUR VISION TO LIFE</div>
          <div className='subTitleContactUs'>Contact us to discover how we can combine our talents to bring your ideas to life. We are a studio specialized in 3D animation, developing full productions and covering outsourcing needs for external clients. We produce all kinds of advertisement campaigns, video game cinematics and visual effects. The character design is one of our main skills as well.</div>
          <form onSubmit={handleSubmit(handleFormSubmit)} className="contact-form">
            <div className='fieldsContainer'>
              <input type="email" id="email" placeholder="e-mail" {...register('email', { required: true })} />
              {errors.email && <span>e-mail is required</span>}
            </div>
            <div className='fieldsContainer'>
              <input type="text" id="subject" placeholder="subject" {...register('subject', { required: true })} />
              {errors.subject && <span>subject is required</span>}
            </div>
            <div className='messageContainer'>
              <textarea id="message" placeholder="message" {...register('message', { required: true })} />
              {errors.message && <span className=''>message is required</span>}
            </div>
            <button className='button' type="submit" disabled={isFormSubmitted}>
              {isFormSubmitted ? 'Thank you for contacting us' : 'Send'}
            </button>
          </form>
          <div className='titleOthers'>O&nbsp;&nbsp;T&nbsp;&nbsp;H&nbsp;&nbsp;E&nbsp;&nbsp;R</div>
          <div className='titleInquiries'>I&nbsp;&nbsp;N&nbsp;&nbsp;Q&nbsp;&nbsp;U&nbsp;&nbsp;I&nbsp;&nbsp;R&nbsp;&nbsp;I&nbsp;&nbsp;E&nbsp;&nbsp;S</div>
          <div className='emailContact'>info@firelightanimation.com</div>
          <div className='subTitleAddress'>Address:<br />Ave. Samuel Lewis y Calle 54E<br />Torre Generalli, Piso 20, Panama City (Panama)</div>
        </div>
      </div>
    </Desktop>
    <Mobile>
        <div className='contactUsContainer'>
        <img className='umbra' src={ImgUmbra} alt="umbra" />
          <div className='blobs-01-Container'>
            <img className='heart_umbra' src={ImgUmbraHeart} alt="umbra" />
            <div className='titleContactUs'>BRING YOUR VISION TO LIFE</div>
            <div className='subTitleContactUs'>Experience the incredible power of imagination and teamwork. Get in touch with us today and discover how we can combine our talents to turn your ideas into extraodrinary animated wonders.</div>
            <form onSubmit={handleSubmit(handleFormSubmit)} className="contact-form">
              <div className='fieldsContainer'>
                <input type="email" id="email" placeholder="e-mail" {...register('email', { required: true })} />
                {errors.email && <span>e-mail is required</span>}
              </div>
              <div className='fieldsContainer'>
                <input type="text" id="subject" placeholder="subject" {...register('subject', { required: true })} />
                {errors.subject && <span>subject is required</span>}
              </div>
              <div className='messageContainer'>
                <textarea id="message" placeholder="message" {...register('message', { required: true })} />
                {errors.message && <span className=''>message is required</span>}
              </div>
              <button className='button' type="submit" disabled={isFormSubmitted}>
                {isFormSubmitted ? 'Thank you for contacting us' : 'Send'}
              </button>
            </form>
            <div className='titleOthers'>O&nbsp;&nbsp;T&nbsp;&nbsp;H&nbsp;&nbsp;E&nbsp;&nbsp;R</div>
            <div className='titleInquiries'>I&nbsp;&nbsp;N&nbsp;&nbsp;Q&nbsp;&nbsp;U&nbsp;&nbsp;I&nbsp;&nbsp;R&nbsp;&nbsp;I&nbsp;&nbsp;E&nbsp;&nbsp;S</div>
            <div className='emailContact'>info@firelightanimation.com</div>
            <div className='subTitleAddress'>Address:<br />Ave. Samuel Lewis y Calle 54E<br />Torre Generalli, Piso 20, Panama City (Panama)</div>
          </div>
        </div>
    </Mobile>
    </>
  );
}